import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import { CREATE_SPECIAL_ADVERTISEMENT } from './gql';

const PlanModal = ({ visibility, toggleVisibility, advertisementRecord }) => {
  const { t } = useTranslation();

  const fields = [
    {
      name: 'partnerBranchIds',
      label: t('labels.partnerBranch'),
      inputType: 'partnerBranchSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
      propsForSelector: {
        partnerId: advertisementRecord?.partnerId,
        searchWithService: false,
      },
    },

    {
      name: 'date',
      label: t('labels.time'),
      inputType: 'dateWithTimeSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
    },
  ];

  const beforeUpload = useCallback(
    (req) => {
      req.advertisementPoolId = advertisementRecord.id;
      req.partnerId = advertisementRecord?.partnerId;
      req.date = req.date - new Date().getTimezoneOffset() * 60 * 1000

      return { variables: { data: req } };
    },
    [advertisementRecord]
  );

  return (
    <ModalFormBuilder
      visibility={visibility}
      toggleModal={toggleVisibility}
      modalTitle={t('titles.planAdvertisement')}
      fields={fields}
      postReq={CREATE_SPECIAL_ADVERTISEMENT}
      postResIndex="createSpecialAdvertisement"
      postResFieldForUpdating="specialAdvertisement"
      showOpenButton={false}
      beforeUpload={beforeUpload}
    />
  );
};

export default PlanModal;

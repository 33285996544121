/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useEffect } from 'react';
import { message, Spin } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import diff from 'deep-diff';
import { gql } from 'apollo-boost';
import EditableTable from './EditableTable';
import ServerWrong from '../Result/ServerWrong';
import usePrevious from '../../hooks/usePrevious';
import Load from '../Load';

const PLACEHOLDER_MUTATION = gql`
  mutation {
    whatever
  }
`;

export default function IntegratedEditableTable({
  columns,
  getReq,
  getResIndex,
  deleteReq,
  deleteResIndex,
  updateReq,
  updateResIndex,
  afterGetFunc,
  filterColumns,
  beforeSave,
  extraOperation,
  upperGetReqCondition = {
    limit: 10,
    offset: 0,
    order: [['createdAt', 'desc']],
  },
  propsForSelector,
  fetchPolicy = 'cache-first',
  operationsDisabledStatus,
  updatable,
  deletable,
  emptyDescription,
  releaseReq,
  unReleaseReq,
  releaseResIndex,
  unReleaseResIndex,
}) {
  // hooks
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [getReqCondition, setGetReqCondition] = useState();
  const { loading, error, data } = useQuery(getReq, {
    variables: { condition: getReqCondition },
    fetchPolicy,
  });
  const previousUpperCondition = usePrevious(upperGetReqCondition);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // #region updateMutation
  const [updateFunc] = useMutation(updateReq, {
    update(cache, { data: responseAfterUpdate }) {
      const updatedRecord = responseAfterUpdate[updateResIndex].newValues[0];

      const queryData = cache.readQuery({
        query: getReq,
        variables: { condition: getReqCondition },
      });

      const newStoreData = queryData[getResIndex].data.map((datum) =>
        datum.id === updatedRecord.id ? updatedRecord : datum
      );

      cache.writeQuery({
        query: getReq,
        variables: { condition: getReqCondition },
        data: {
          [getResIndex]: {
            ...queryData[getResIndex],
            data: newStoreData,
          },
        },
      });
    },
  });
  // #endregion

  // #region deleteMutation
  const [deleteFunc] = useMutation(deleteReq, {
    update(cache, { data: deleteData }) {
      const responseFromMutation = deleteData[deleteResIndex].deletedRows[0];

      const queryData = cache.readQuery({
        query: getReq,
        variables: { condition: getReqCondition },
      });

      const newStateAfterDelete = queryData[getResIndex].data.filter(
        (datum) => datum.id !== responseFromMutation.id
      );

      cache.writeQuery({
        query: getReq,
        variables: { condition: getReqCondition },
        data: {
          [getResIndex]: {
            ...queryData[getResIndex],
            data: newStateAfterDelete,
          },
        },
      });
    },
  });
  // #endregion

  // #region  releaseMutation
  const [releaseFunc] = useMutation(releaseReq || PLACEHOLDER_MUTATION);
  const [unReleaseFunc] = useMutation(unReleaseReq || PLACEHOLDER_MUTATION);
  // #endregion

  useEffect(() => {
    if (
      upperGetReqCondition &&
      diff(previousUpperCondition, upperGetReqCondition)
    ) {
      setGetReqCondition(upperGetReqCondition);
    }
  }, [upperGetReqCondition, previousUpperCondition]);

  useEffect(() => {
    if (afterGetFunc) {
      const newData = afterGetFunc(data);
      setTableData(newData);
    } else {
      setTableData(data);
    }
  }, [data, afterGetFunc]);

  // handles

  // #region  handlePageNumberChange
  const handlePageNumberChange = useCallback(
    (pageNumber) => {
      const currentOffset = (pageNumber - 1) * 10;
      setGetReqCondition({
        ...getReqCondition,
        offset: currentOffset,
      });
      setCurrentPageNumber(pageNumber);
      setTableData();
    },
    [getReqCondition]
  );
  // #endregion

  // #region  handleDelete
  const handleDelete = useCallback(
    async (id) => {
      const response = await deleteFunc({
        variables: { where: { id } },
      });
      const { __typename, affectedRows } = response?.data?.[deleteResIndex];

      if (affectedRows === 1) {
        message.success(t('messages.successful'));
      } else if (affectedRows === 0) {
        message.error(t('messages.recordNotFound'));
      } else if (__typename === 'DeleteError') {
        message.error(t('errors.requestFailed'));
      }
    },
    [deleteFunc, deleteResIndex, t]
  );
  // #endregion

  // #region handleSave
  const handleSave = useCallback(
    async (newRecord) => {
      let response;
      const recordId = newRecord.id;
      delete newRecord.key;
      delete newRecord.id;

      if (beforeSave) {
        const req = beforeSave(recordId, newRecord);
        response = await updateFunc(req);
      } else {
        response = await updateFunc({
          variables: {
            where: { id: recordId },
            newValues: newRecord,
          },
        });
      }

      const responseTypeName = response?.data?.[updateResIndex]?.__typename;

      if (responseTypeName === 'NotFoundResult') {
        message.info(t('messages.recordnotfound'));
        return { finishEdit: true };
      }
      if (responseTypeName.endsWith('Error')) {
        message.error(t('messages.somethingwrong'));
        return {
          finishEdit: false,
          errorsMessages: response?.data?.[updateResIndex],
        };
      }
      message.success(t('messages.successUpdate'));
      return { finishEdit: true };
    },
    [updateFunc, updateResIndex, t, beforeSave]
  );
  // #endregion

  // #region search
  const handleSearch = useCallback(
    (inputValue) => {
      setGetReqCondition({
        ...getReqCondition,
        offset: 0,
        search: {
          ...filterColumns,
          term: inputValue,
        },
      });
      setCurrentPageNumber(1);
    },
    [getReqCondition, filterColumns, setCurrentPageNumber]
  );
  // #endregion

  // #region release
  const toggleRelease = useCallback(
    async (id, reqType) => {
      let response;
      let resIndex;

      if (reqType === 'release') {
        response = await releaseFunc({ variables: { id } });
        resIndex = releaseResIndex;
      } else {
        response = await unReleaseFunc({ variables: { id } });
        resIndex = unReleaseResIndex;
      }

      if (response?.data?.[resIndex].success) {
        const formattedTableData = tableData?.[getResIndex]?.data.map(
          (datum) => {
            if (datum.id === id) {
              datum.isReleased = reqType === 'release';
              return datum;
            }
            return datum;
          }
        );
        setTableData({
          [getResIndex]: {
            ...tableData[getResIndex],
            data: formattedTableData,
          },
        });
        message.success(t('messages.successful'));
      } else {
        const errorMessage =
          reqType === 'release'
            ? message.error(t('errors.timelineConflict'))
            : message.error(t('errors.requestFailed'));
        message.error(errorMessage);
      }
    },
    [
      t,
      releaseFunc,
      unReleaseFunc,
      tableData,
      getResIndex,
      releaseResIndex,
      unReleaseResIndex,
    ]
  );
  // #endregion

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading} indicator={<Load />}>
      <EditableTable
        columns={columns}
        data={tableData?.[getResIndex]?.data}
        totalDataCount={tableData?.[getResIndex]?.totalCount}
        handlePageNumberChange={handlePageNumberChange}
        handleDelete={handleDelete}
        handleSave={handleSave}
        handleSearch={handleSearch}
        isFilterActivated={!!filterColumns}
        extraOperation={extraOperation}
        propsForSelector={propsForSelector}
        currentPageNumber={currentPageNumber}
        operationsDisabledStatus={operationsDisabledStatus}
        updatable={updatable}
        deletable={deletable}
        emptyDescription={emptyDescription}
        toggleRelease={toggleRelease}
      />
    </Spin>
  );
}

/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './login.css';
import { Form, Input, Button, Col, Row, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { RESET_PASSWORD } from './gql';
import { Flags } from '../../components';
import loginBg from './img/loginBg.jpg';
import logo from './img/logo.svg';

export default function Index() {
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const goBack = () => {
    history.push('/');
  };

  const [resetPassword] = useMutation(RESET_PASSWORD);

  async function handleSubmit() {
    try {
      if (email == null) {
        message.error(t('messages.email'));
      } else {
        const result = await resetPassword({ variables: { email } });
        if (result?.data?.resetPassword === true) {
          message.success(t('messages.forgotMessage'));
          history.push('/');
        } else message.error(t('messages.hata'));
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  return (
    <div
      className="background"
      style={{
        backgroundImage: `url(${loginBg})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="form-container">
        <Row style={{ marginBottom: 40 }}>
          <Col span={12}>
            <img
              src={logo}
              alt="logo"
              style={{
                width: 100,
              }}
            />
          </Col>
          <Col span={12}>
            <div style={{ float: 'right' }}>
              <Flags />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={24} xs={24} md={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <h2 style={{ color: '#fff' }}>{t('labels.forgotMyPassword')}</h2>
            </div>
          </Col>
          <Col sm={24} xs={24} md={24}>
            {' '}
            <p
              style={{
                // color: '#ffe7ba',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              {t('labels.forgotPasswordMessage')}
            </p>
          </Col>
        </Row>
        <Row className="forgotInput">
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 3 }}
            md={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
          >
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('messages.email'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  className="userInput"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder={t('messages.email')}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="ghost"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    width: '50%',
                    color: '#ff4d4f',
                    borderColor: '#ff4d4f',
                  }}
                  onClick={goBack}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button
                  type="ghost"
                  onClick={handleSubmit}
                  className="login-form-button"
                  style={{
                    width: '50%',
                    color: '#36cfc9',
                    borderColor: '#36cfc9',
                  }}
                >
                  {t('buttons.send')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

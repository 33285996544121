import { gql } from 'apollo-boost';

export const PARTNER_BRANCH_SESSION_SUMMARIES = gql`
  query partnerBranchSessionSummaries($condition: Condition) {
    partnerBranchSessionSummaries(condition: $condition) {
      __typename
      ... on PaginatedPartnerBranchSessionSummaryResponse {
        totalCount
        data {
          id
          version
          lastOnline
          lastOffline
          status
          partnerBranch {
            ... on PartnerBranch {
              id
              name
              partner {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const ALL_PARTNER_BRANCH_STATUS = gql`
  query allPartnerBranchStatus($partnerId: Int) {
    allPartnerBranchStatus(partnerId: $partnerId) {
      __typename
      ... on TotalSessionSummary {
        onlineCount
        offlineCount
      }
    }
  }
`;

export const PARTNER_TOTAL_SESSION_SUMMARY = gql`
  query partnerTotalSessionSummary($condition: Condition) {
    partnerTotalSessionSummary(condition: $condition) {
      __typename
      ... on PaginatedPartnerTotalSessionSummaryResponse {
        totalCount
        data {
          onlineCount
          offlineCount
          partner {
            id
            name
          }
        }
      }
    }
  }
`;

export const PLACEHOLDER = gql`
  mutation deletePartner($where: JSON!) {
    deletePartner(where: $where) {
      __typename

      ... on DeleteError {
        message
      }
    }
  }
`;

import React from 'react';
import { Layout, Breadcrumb } from 'antd';
import './style.css';

const { Content } = Layout;

export default function Contents({ children }) {
  return (
    <Content style={{ padding: '0 20px', marginTop: 10 }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
      </Breadcrumb>

      <div
        style={{
          padding: 24,
          minHeight: 580,
          background: '#fff',
          boxShadow: '0 0 5px #aaaaaa',
          borderRadius: '5px',
        }}
        className="content"
      >
        {children}
      </div>
    </Content>
  );
}

import React, { useState, useCallback } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PartnerAndBranchSelector,
  DateSelector,
} from '../../components/Selectors';
import '../../components/MainLayout/style.css';

const TimelineFilter = ({ handleOnSelect }) => {
  const { t } = useTranslation();
  const [selectedBranch, setSelectedBranch] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const handleFilterButton = useCallback(() => {
    handleOnSelect({ partnerBranchId: selectedBranch, date: selectedDate });
  }, [handleOnSelect, selectedBranch, selectedDate]);

  return (
    <div>
      <Row gutter={(16, 16)}>
        <Col md={24} lg={24} xxl={24}>
          <Card>
            <Row gutter={(16, 16)}>
              <Col xs={24} md={24} lg={{ span: 8 }}>
                <div className="partnerBranch">
                  <PartnerAndBranchSelector
                    onChange={(branchId) => setSelectedBranch(branchId)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={3}>
                <div className="dateDashboard">
                  <DateSelector onChange={(date) => setSelectedDate(date)} />
                </div>
              </Col>
              <Col md={{ span: 5 }} xs={{ span: 12 }} sm={12}>
                <Button
                  className="filterButton"
                  disabled={!(selectedBranch && selectedDate)}
                  ghost
                  type="primary"
                  onClick={handleFilterButton}
                >
                  {t('buttons.filter')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TimelineFilter;

import React, { useCallback } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './datepicker.css';

const DateSelector = ({ onChange, initialrecord, propsForSelector }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (date, timeString) => {
      const stringArray = timeString.split('/');
      const formattedDate = `${stringArray[2]}-${stringArray[1]}-${stringArray[0]}`;
      const dateWithZeroTime = moment.utc(formattedDate).valueOf();

      onChange(dateWithZeroTime);
    },
    [onChange]
  );

  return (
    <div className="datepicker">
      <DatePicker
        format="DD/MM/YYYY"
        placeholder={t('placeholders.selectDate')}
        defaultValue={
          initialrecord?.[propsForSelector?.fieldName]
            ? moment(initialrecord?.[propsForSelector?.fieldName])
            : null
        }
        onChange={handleChange}
      />
    </div>
  );
};

export default DateSelector;

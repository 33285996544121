import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SEARCH_USERS } from './gql';

const { Option } = Select;

const UserSelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  // hooks
  const [selectedOption, setSelectedOption] = useState();
  // const [condition, setCondition] = useState();
  const { loading, error, data } = useQuery(SEARCH_USERS, {
    variables: { condition: { limit: -1 } },
  });

  // const handleSearch = useCallback(
  //   (input) => {
  //     if (input) {
  //       const where = propsForSelector.role
  //         ? { username: { _like: `%${input}%` }, role: propsForSelector.role }
  //         : { username: { _like: `%${input}%` } };
  //       setCondition({
  //         limit: -1,
  //         where,
  //       });
  //     }
  //   },
  //   [propsForSelector]
  // );

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  if (error) {
    return t('message.somethingwrong');
  }

  return (
    <Select
      defaultValue={initialrecord?.username}
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.chooseUser')}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      // onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
    >
      {data?.users?.data &&
        data.users.data.map((user) => (
          <Option value={user.id} key={user.id}>
            {user.username}
          </Option>
        ))}
    </Select>
  );
};

export default UserSelector;

/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import './login.css';
import { Form, Input, Button, Col, Row, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, Link } from 'react-router-dom';
import LOGIN from './gql';
import { Flags } from '../../components';
import loginBg from './img/KAPAK.jpg';
import logo from './img/logo.svg';

export default function Index() {
  const history = useHistory();
  const { t } = useTranslation();

  const [signIn] = useMutation(LOGIN);

  useEffect(() => {
    if (
      window.localStorage.getItem('user') &&
      window.localStorage.getItem('token')
    ) {
      history.push('/welcome');
    }
  }, [history]);

  // eslint-disable-next-line consistent-return
  const onFinish = async (inputValues) => {
    const { username, password } = inputValues;
    try {
      const response = await signIn({ variables: { username, password } });
      const { data, errors } = response;

      if (errors || data.login.__typename === 'NotFoundResult') {
        return message.error(t('errors.checkUsernamePassword'));
      }

      const { token, user } = data.login;
      window.localStorage.setItem('token', token);
      window.localStorage.setItem('user', JSON.stringify(user));
      window.location.href += 'welcome'; // force refresh to get token for apollo client.
    } catch (error) {
      console.error(error);
      message.error(t('errors.unSuccessful'));
    }
  };

  return (
    <div
      className="background"
      style={{
        backgroundImage: `url(${loginBg})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="form-container">
        <Row style={{ marginBottom: 40 }}>
          <Col span={12}>
            <img
              src={logo}
              alt="logo"
              style={{
                width: 100,
              }}
            />
          </Col>
          <Col span={12}>
            <div style={{ float: 'right' }}>
              <Flags />
            </div>
          </Col>
        </Row>
        <Row className="userInput">
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 3 }}
            md={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
          >
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredUsername'),
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t('placeholders.username')}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredPassword'),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={t('placeholders.password')}
                />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Link className="forgotPassword" to="/forgotPassword">
                  {t('labels.forgotMyPassword')}
                </Link>
              </Form.Item>
              <Form.Item>
                <Button
                  type="ghost"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    width: '100%',
                    color: '#d4380d',
                    borderColor: '#d4380d',
                  }}
                >
                  {t('buttons.login')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

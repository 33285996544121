import React, { useState } from 'react';
import LeftMenu from './LeftMenu';
import { Drawer, Button } from 'antd';
import { AlignLeftOutlined, CloseOutlined } from '@ant-design/icons';
export default function Menu() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <nav className="menuBar">
      <div className="menuCon">
        <Button className="barsMenu" onClick={showDrawer}>
          <AlignLeftOutlined
            className="barsBtn"
            style={{
              fontSize: '25px',
              color: '#007bff',
              marginTop: '8px',
              marginRight: '10px',
            }}
          />
        </Button>

        <img
          src={require('../MainLayout/img/logo.svg')}
          alt="logo"
          className="barslogo"
        />
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <Button className="barsMenuX" onClick={onClose}>
            <CloseOutlined className="barsBtnx" />
          </Button>
          <LeftMenu />
        </Drawer>
      </div>
    </nav>
  );
}

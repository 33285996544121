/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tooltip, Button } from 'antd';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import {
  PLAYLISTS,
  UPDATE_PLAYLIST,
  DELETE_PLAYLIST,
  CREATE_PLAYLIST,
} from './gql';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';
import userInfoGetter from '../../utils.js/userInfoGetter';
import EditModal from './EditModal';

const fieldsCreator = (t) => {
  return [
    {
      name: 'name',
      label: t('titles.playlist'),
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
        {
          type: 'string',
          message: t('warnings.string'),
        },
        {
          min: 2,
          message: t('warnings.min'),
        },
        {
          max: 150,
          message: t('warnings.max'),
        },
      ],
    },
    {
      name: 'songPoolWithPartners',
      label: t('titles.songPool'),
      inputType: 'songPoolWithPartnersSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
    },
  ];
};

const columnsConfig = (t, role) => {
  const onlyForAdminColumns =
    role === 'PARTNER'
      ? []
      : [
          {
            title: t('columns.createdBy'),
            dataIndex: 'createdBy',
            key: 'createdBy',
            editable: false,
            render: (_, record) => record?.createdByUserName,
          },
          {
            title: t('columns.partners'),
            dataIndex: 'partners',
            key: 'partners',
            inputType: 'partnerSelector',
            editable: true,
            render: (_, record) =>
              record?.partnerNames?.map((name,index) => <h4 key={index}>{name}</h4>),
          },
        ];
  return [
    {
      title: t('titles.playlist'),
      dataIndex: 'name',
      key: 'name',
      editable: true,
    },
    {
      title: t('titles.songPoolName'),
      dataIndex: 'songPoolId',
      key: 'songPoolId',
      inputType: 'songPoolSelector',
      render: (_, record) => {
        return record.songPoolName || t('warnings.noData');
      },
      editable: false,
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
    },
    ...onlyForAdminColumns,
    {
      title: t('columns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: t('columns.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
  ];
};
export default function PlaylistDesc() {
  const { role, partnerId, userId } = userInfoGetter();
  // hooks
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);
  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const [chosenRecordForEdit, setChosenRecordForEdit] = useState();

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t, role);

  const upperGetReqConditionCreator = () => {
    const where = role === 'PARTNER' ? { partnerId } : undefined;
    return {
      limit: 10,
      offset: 0,
      order: [['createdAt', 'desc']],
      where,
    };
  };

  const afterGetFunc = useCallback(
    (response) => {
      const newData = response?.playlists?.data?.map((playlist) => ({
        id: playlist.id,
        name: playlist.name,
        songPoolName: playlist?.songPool?.name,
        songPoolId: playlist?.songPool?.id,
        createdAt: humanizeTimestamp(playlist.createdAt),
        updatedAt: humanizeTimestamp(playlist.updatedAt),
        createdBy: playlist?.createdBy,
        createdByUserName: playlist?.createdBy?.firstName
          ? `${playlist?.createdBy?.firstName} ${playlist?.createdBy?.lastName}`
          : t('warnings.noData'),
        partners: playlist?.partners,
        partnerNames: playlist?.partners?.map((partner) => partner.name),
      }));

      return {
        ...response,
        playlists: { ...response?.playlists, data: newData },
      };
    },
    [t]
  );

  const beforeUpload = useCallback(
    (formValues) => {
      const { songPoolId, partnerIds } = formValues.songPoolWithPartners;
      delete formValues.songPoolWithPartners;

      return {
        variables: {
          data: { ...formValues, createdBy: userId, songPoolId, partnerIds },
        },
      };
    },
    [userId]
  );

  const extraOperationCreator = (record) => (
    <Tooltip title={t('buttons.edit')}>
      <Button
        style={{ marginBottom: 5, marginRight: 5 }}
        ghost
        type="primary"
        onClick={() => {
          setChosenRecordForEdit(record);
          setEditModalVisibility(true);
        }}
      >
        {t('buttons.update')}
      </Button>
    </Tooltip>
  );

  return (
    <>
      <Row>
        <Col span={24} style={{ marginTop: '20px' }}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleTrueFalse}
            modalTitle={t('titles.newPlaylist')}
            showModalButtonLabel={t('titles.newPlaylist')}
            fields={fields}
            postReq={CREATE_PLAYLIST}
            postResIndex="createPlaylist"
            postResFieldForUpdating="playlist"
            willBeUpdatedQuery={PLAYLISTS}
            willBeUpdatedQueryName="playlists"
            willBeUpdatedQueryExtraVariable={
              role === 'PARTNER' ? { where: { partnerId } } : undefined
            }
            beforeUpload={beforeUpload}
          />
        </Col>
        <Col span={24}>
          <IntegratedEditableTable
            columns={columns}
            upperGetReqCondition={upperGetReqConditionCreator()}
            getReq={PLAYLISTS}
            getResIndex="playlists"
            updateReq={UPDATE_PLAYLIST}
            updateResIndex="updatePlaylist"
            deleteReq={DELETE_PLAYLIST}
            deleteResIndex="deletePlaylist"
            afterGetFunc={afterGetFunc}
            filterColumns={{ playlist: ['name'] }}
            extraOperation={extraOperationCreator}
            updatable={false}
            deletable={userId===3948}
            operationsDisabledStatus={userId!==3948 }

          />
        </Col>
      </Row>
      <EditModal
        visibility={editModalVisibility}
        setVisibility={setEditModalVisibility}
        chosenRecordForEdit={chosenRecordForEdit}
        setChosenRecordForEdit={setChosenRecordForEdit}
      />
    </>
  );
}

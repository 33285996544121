import React, { useState, useCallback } from 'react';
import { Row, Col, Button, Card, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DateSelector } from '../../../components/Selectors';
import '../../../components/MainLayout/style.css';

const TimelineFilter = ({ handleOnSelect }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();

  const handleFilterButton = useCallback(() => {
    handleOnSelect(selectedDate);
  }, [handleOnSelect, selectedDate]);
  const text = <span>{t('labels.dateTooltip')}</span>;
  return (
    <div>
      <Row gutter={(16, 16)}>
        <Col md={24} lg={24} xxl={24}>
          <Card>
            <Row gutter={(16, 16)}>
              <Col xs={12} sm={12} md={3}>
                <Tooltip title={text}>
                  <div className="dateDashboard">
                    <DateSelector onChange={(date) => setSelectedDate(date)} />
                  </div>
                </Tooltip>
              </Col>
              <Col md={{ span: 5 }} xs={{ span: 12 }} sm={12}>
                <Button
                  className="filterButton"
                  disabled={!selectedDate}
                  ghost
                  type="primary"
                  onClick={handleFilterButton}
                >
                  {t('buttons.filter')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TimelineFilter;

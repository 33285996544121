import React from 'react';
import { Layout } from 'antd';
import Contents from './Contents';
import Menu from '../Menu';
import Header from './Headers';

import MenuSiders from './MenuSiders';

const { Footer } = Layout;

export default function Index({ children }) {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Menu />
      <Layout>
        <MenuSiders />
        <Layout>
          <Contents>{children}</Contents>
          <Footer style={{ textAlign: 'center' }}>©2020 RTP Medya</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
